export const LabelConstants ={
  "label00001" : "登录",
  "label00002" : "用户名",
  "label00003" : "密码",
  "label00004" : "请输入用户名和密码。",
  "label00005" : "知识库配置",
  "label00006" : "知识库查询",
  "label00007" : "搜索引擎交互",
  "label00008" : "供应商风险评估",
  "label00009" : "文件交互",
  "label00010" : "生成式BI",
  "label00011" : "搜索引擎及内部数据交互",
  "label00012" : "数据提取",
  "label00013" : "语言翻译",
  "label00014" : "内容生成",
  "label00015" : "创建用户",
  "label00016" : "有效期",
  "label00017" : "表示名",
  "label00018" : "邮件",
  "label00019" : "角色",
  "label00020" : "提交",
  "label00021" : "操作",
  "label00022" : "有",
  "label00023" : "无",
  "label00024" : "设定",
  "label00025" : "权限设定",
  "label00026" : "处理中",
  "label00027" : "用户一览",
  "label00028" : "AI 应用中台",
  "label00029" : "请在这里选择您需要的应用,欢迎咨询。",
  "label00030" : "应用目录",
  "label00031" : "应用",
  "label00032" : "全部",
  "label00033" : "工具",
  "label00034" : "客户支持",
  "label00035" : "文娱",
  "label00036" : "金融",
  "label00037" : "人力资源",
  "label00038" : "市场营销",
  "label00039" : "效率化",
  "label00040" : "编码",
  "label00041" : "销售",
  "label00042" : "数据",
  "label00043" : "任务",
  "label00044" : "历史",
  "label00045" : "设定",
  "label00046" : "应用",
  "label00047" : "退出",
  "label00048" : "人工智能栈",
  "label00049" : "选择知识库",
  "label00050" : "新建知识库",
  "label00051" : "删除知识库",
  "label00052" : "知识库名称",
  "label00053" : "请输入知识库名称，不要输入中文",
  "label00054" : "知识库描述",
  "label00055" : "请输入知识库描述，例如知识范围，应用场景等",
  "label00056" : "向量库类型",
  "label00057" : "向量模型",
  "label00058" : "新建",
  "label00059" : "取消",
  "label00060" : "知识库信息：",
  "label00061" : "知识库描述：",
  "label00062" : "更新",
  "label00063" : "编辑",
  "label00064" : "创建时间",
  "label00065" : "文件数",
  "label00066" : "文件分片参数配置：",
  "label00067" : "文本切片长度",
  "label00068" : "相邻切片重合长度",
  "label00069" : "上传知识文件",
  "label00070" : "上传知识",
  "label00071" : "将文件拖放到此处或",
  "label00072" : "点击上传",
  "label00073" : "目前仅支持上传Md、Docx、Pdf、Xlsx、Csv、Html、Jsonl、Txt、Jpg、Jpeg、Png等文件类型",
  "label00074" : "文件名:",
  "label00075" : "文件大小:",
  "label00076" : "知识库文件信息：",
  "label00077" : "文件大小： ",
  "label00078" : "切片数量： ",
  "label00079" : "上传时间： ",
  "label00080" : "下载",
  "label00081" : "切片",
  "label00082" : "保存修改",
  "label00083" : "总文档数:",
  "label00084" : "当前页:",
  "label00085" : "上一页",
  "label00086" : "下一页",
  "label00087" : "删除",
  "label00088" : "重新向量化",
  "label00089" : "选择AI模型：",
  "label00090" : "参数设定",
  "label00091" : "选择提示词模板：",
  "label00092" : "查看",
  "label00093" : "已复制",
  "label00094" : "复制",
  "label00095" : "自定义系统提示词：",
  "label00096" : "请输入自定义系统提示词",
  "label00097" : "历史对话轮数：",
  "label00098" : "知识库配置",
  "label00099" : "请选择知识库：",
  "label00100" : "匹配知识条数：",
  "label00101" : "知识匹配阈值：",
  "label00102" : "知识库匹配结果",
  "label00103" : "请输入查询信息...",
  "label00104" : "AI交互中",
  "label00105" : "选择信息源时间范围：",
  "label00106" : "至",
  "label00107" : "选择供应商：",
  "label00108" : "输入任意供应商名：",
  "label00109" : "开始评估",
  "label00110" : "AI评估中",
  "label00111" : "网络信息源情报",
  "label00112" : "检索关键字：",
  "label00113" : "负面新闻和风险信息 战争 灾害",
  "label00114" : "搜索引擎配置",
  "label00115" : "请选择搜索引擎：",
  "label00116" : "匹配搜索结果条数：",
  "label00117" : "风险预案知识库配置",
  "label00118" : "ZH_text_RISK",
  "label00119" : "",
  "label00120" : "",
  "label00121" : "",
  "label00122" : "",
  "label00123" : "",
  "label00124" : "",
  "label00125" : "",
  "label00126" : "",
  "label00127" : "",
  "label00128" : "",
  "label00129" : "",
  "label00130" : "",
  "label00131" : "",
  "label00132" : "",
  "label00133" : "",
  "label00134" : "",
  "label00135" : "",
  "label00136" : "",
  "label00137" : "",
  "label00138" : "",

}