export const APP_CARD = [
  {
    "name": "知识库配置",
    "slug": "knowledgebase-management",
    "icon": "tool",
    "category_slugs": [
        "文娱",
        "客户支持",
        "销售",
        "人力资源"
    ],
    "description": "创建知识库，通过文件导入知识并根据选择的向量库类型和向量模型进行向量化。支持对向量化数据的调整等。",
    "app": {
        "type_slug": "web"
    }
 }, 
 {
  "name": "知识库查询",
  "slug": "chatbot-with-knowledgebase",
  "icon": "chatbot",
  "category_slugs": [
      "文娱",
      "客户支持",
      "销售",
      "人力资源"
  ],
  "description": "根据选择的大模型以及设定的相关参数从指定的知识库中查询相关数据并根据特定的格式进行输出。知识库检索不到特定的情况下会根据大模型自身能力回答。",
  "app": {
      "type_slug": "text-chat"
  }
},
{
  "name": "与搜索引擎交互",
  "slug": "chatbot-with-internet-only",
  "icon": "internet",
  "category_slugs": [
      "工具",
      "文娱",
      "客户支持",
      "销售",
      "人力资源"
  ],
  "description": "通过指定的搜索引擎从互联网搜索数据，并根据指定的大语言模型和设定的相关参数进行分析和输出。互联网搜不到的情况下会根据大模型自身能力回答。",
  "app": {
      "type_slug": "text-chat"
  }
}, 
{
  "name": "供应商风险评估",
  "slug": "supplier-risk-assesment",
  "icon": "bot",
  "category_slugs": [
      "销售",
      "市场营销",
      "工具"
  ],
  "description": "根据搜索关键字从指定的互联网搜索供应商的风险信息，并根据指定的大语言模型和微调模型以及设定的参数对风险信息进行分析和归类，并从相关知识库中查询预案方法。",
  "app": {
      "type_slug": "agent"
  }
},
{
  "name": "与文件交互",
  "slug": "file-chatbot",
  "icon": "tool",
  "category_slugs": [
      "效率化",
      "编码",
      "金融"
  ],
  "description": "上传文件，比如PDF，CSV等，根据文件提出问题，由指定的AI模型以及相关参数生成回答。",
  "app": {
      "type_slug": "text-chat"
  }
},
  {
      "name": "生成式BI",
      "slug": "ai-sdr",
      "icon": "bot",
      "category_slugs": [
          "销售",
          "市场营销",
          "工具"
      ],
      "description": "根据自然语言自动生成SQL并查询数据库然后自动生成BI报表。",
      "app": {
          "type_slug": "agent"
      }
  },
  // {
  //     "name": "Character Chat",
  //     "slug": "character-chatbot",
  //     "icon": "chatbot",
  //     "category_slugs": [
  //         "文娱",
  //         "客户支持",
  //         "人力资源"
  //     ],
  //     "description": "Bring your favorite characters to life using this template. Simply define a few features of the character you want to create a bot for and start chatting away.",
  //     "app": {
  //         "type_slug": "text-chat"
  //     }
  // },
  {
      "name": "与搜索引擎以及内部数据交互",
      "slug": "chatbot-with-internet-and-owndata",
      "icon": "internet",
      "category_slugs": [
          "工具",
          "文娱",
          "客户支持",
          "销售",
          "人力资源"
      ],
      "description": "优先从指定的数据源或者知识库检索数据，如果检索不到的情况，从互联网进行搜索，并根据指定的大语言模型和设定的参数生成回答。",
      "app": {
          "type_slug": "agent"
      }
  },
  {
      "name": "数据提取",
      "slug": "data-extractor",
      "icon": "tool",
      "category_slugs": [
          "编码",
          "效率化",
          "销售"
      ],
      "description": "根据设定的特征，从提供的非结构化数据的文档中提取结构化数据。",
      "app": {
          "type_slug": "web"
      }
  },
  {
      "name": "语言翻译",
      "slug": "language-translator",
      "icon": "text",
      "category_slugs": [
          "市场营销",
          "效率化"
      ],
      "description": "利用AI模型将给定的文本内容或者文件内容翻译为指定的语言。",
      "app": {
          "type_slug": "web"
      }
  },
  {
      "name": "内容生成",
      "slug": "marketing-content-generator",
      "icon": "text",
      "category_slugs": [
          "市场营销",
          "销售",
          "效率化"
      ],
      "description": "用于创建营销内容。根据特定的指令和参数，生成各种格式和风格的文本，如广告语、博客文章、社交媒体帖子等，从而提高营销效率和效果",
      "app": {
          "type_slug": "web"
      }
  },
  // {
  //     "name": "Realtime Avatar Chat",
  //     "slug": "realtime-avatar-chat",
  //     "icon": "voice",
  //     "category_slugs": [
  //         "客户支持",
  //         "市场营销",
  //         "销售"
  //     ],
  //     "description": "Use your HeyGen's Avatar to answer questions in real time directly from your data.",
  //     "app": {
  //         "type_slug": "web"
  //     }
  // },
  // {
  //     "name": "Voice Answers",
  //     "slug": "voice-answers",
  //     "icon": "voice",
  //     "category_slugs": [
  //         "文娱",
  //         "客户支持",
  //         "效率化"
  //     ],
  //     "description": "Augment your search with AI and provide voice-based answers for a richer experience. Ensure to add your ElevenLabs API key in Settings.",
  //     "app": {
  //         "type_slug": "web"
  //     }
  // },
  // {
  //     "name": "Voice Chat",
  //     "slug": "voice-chat",
  //     "icon": "voice",
  //     "category_slugs": [
  //         "文娱",
  //         "客户支持",
  //         "效率化"
  //     ],
  //     "description": "With this template, create an app that lets users ask questions by recording their voice. Whisper transcribes user's speech to text, passes it to ChatGPT along with relevant data to generate an answer. ElevenLabs will generate the final response in the provided voice",
  //     "app": {
  //         "type_slug": "text-chat"
  //     }
  // },
  // {
  //     "name": "Website Chatbot",
  //     "slug": "website-chatbot",
  //     "icon": "tool",
  //     "category_slugs": [
  //         "客户支持",
  //         "市场营销",
  //         "销售",
  //         "效率化"
  //     ],
  //     "description": "A chatbot that can answer user's questions based on your own content and can easily be embedded into your website.",
  //     "app": {
  //         "type_slug": "text-chat"
  //     }
  // }
];