export const messages ={
  "MSG00001" : "认证失败：用户不存在。",
  "MSG00002" : "认证失败：密码不正确。",
  "MSG00003" : "认证失败：密码失效。",
  "MSG00004" : "请输入用户名和密码。",
  "MSG00005": "Failed to retrieve user list information.",
  "MSG00006": "User information updated successfully.",
  "MSG00007": "Failed to update user information.",
  "MSG00008": "User deleted successfully.",
  "MSG00009": "Failed to delete user.",
  "MSG00010": "User created successfully.",
  "MSG00011": "Failed to create user.",
  "MSG00012": "Knowledgebase deleted successfully!",
  "MSG00013": "<span style='color:red'>LLM connection error, unable to respond. Please check if the LLM is connected properly.\n\n</span>",
  "MSG00014" : "No relevant documents found, this answer is provided by the model’s own capabilities",
  "MSG00015" : "Based on the known information retrieved and the analysis by the AI model, no risks were found. Please adjust the parameters and conduct the evaluation again, such as the number of search engine results.",

  "CMSG00001" : "服务器错误。",
}