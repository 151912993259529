export const APP_CARD = [
  {
    "name": "KnowledgeBase Config",
    "slug": "knowledgebase-management",
    "icon": "tool",
    "category_slugs": [
        "entertainment",
        "customer-support",
        "sales",
        "hr"
    ],
    "description": "Create a knowledgebase, import knowledge through files, and vectorize it based on the selected vector library type and vector model. Supports adjustments to vectorized data, etc.",
    "app": {
        "type_slug": "web"
    }
 }, 
 {
  "name": "KnowledgeBase Query",
  "slug": "chatbot-with-knowledgebase",
  "icon": "chatbot",
  "category_slugs": [
      "entertainment",
      "customer-support",
      "sales",
      "hr"
  ],
  "description": "Query relevant data from the specified knowledgebase based on the selected LLM and parameters, and output it in a specific format. If the knowledgebase cannot retrieve specific information, it will respond based on the LLM's own capabilities.",
  "app": {
      "type_slug": "text-chat"
  }
},
{
  "name": "Chat with Search Engine",
  "slug": "chatbot-with-internet-only",
  "icon": "internet",
  "category_slugs": [
      "agents",
      "entertainment",
      "customer-support",
      "sales",
      "hr"
  ],
  "description": "Search data from the internet through the specified search engine, analyze and output it based on the specified large language model and set parameters. If the internet cannot find the information, it will respond based on the large model's own capabilities.",
  "app": {
      "type_slug": "text-chat"
  }
}, 
{
  "name": "Supplier Risk Assessment",
  "slug": "supplier-risk-assesment",
  "icon": "bot",
  "category_slugs": [
      "sales",
      "marketing",
      "agents"
  ],
  "description": "Search for supplier risk information from the specified internet based on search keywords, analyze and classify the risk information based on the specified large language model and fine-tuned model, and query contingency methods from the relevant knowledge base.",
  "app": {
      "type_slug": "agent"
  }
},
{
  "name": "Chat with Files",
  "slug": "file-chatbot",
  "icon": "tool",
  "category_slugs": [
      "productivity",
      "programming",
      "finance"
  ],
  "description": "Upload files, such as PDFs, CSVs, etc., ask questions based on the files, and generate answers by the specified AI model and related parameters.",
  "app": {
      "type_slug": "text-chat"
  }
},
  {
      "name": "Generative BI",
      "slug": "ai-sdr",
      "icon": "bot",
      "category_slugs": [
          "sales",
          "marketing",
          "agents"
      ],
      "description": "Automatically generate SQL based on natural language, query the database, and then automatically generate BI reports.",
      "app": {
          "type_slug": "agent"
      }
  },
  {
      "name": "Chat with Own Data",
      "slug": "chatbot-with-internet-and-owndata",
      "icon": "internet",
      "category_slugs": [
          "agents",
          "entertainment",
          "customer-support",
          "sales",
          "hr"
      ],
      "description": "Prioritize retrieving data from the specified data source or knowledge base. If the retrieval fails, search the internet and generate answers based on the specified large language model and set parameters.",
      "app": {
          "type_slug": "agent"
      }
  },
  {
      "name": "Data Extraction",
      "slug": "data-extractor",
      "icon": "tool",
      "category_slugs": [
          "programming",
          "productivity",
          "sales"
      ],
      "description": "Extract structured data from provided unstructured data documents based on set features.",
      "app": {
          "type_slug": "web"
      }
  },
  {
      "name": "Language Translation",
      "slug": "language-translator",
      "icon": "text",
      "category_slugs": [
          "marketing",
          "productivity"
      ],
      "description": "Use AI models to translate the given text content or file content into the specified language.",
      "app": {
          "type_slug": "web"
      }
  },
  {
      "name": "Content Generation",
      "slug": "marketing-content-generator",
      "icon": "text",
      "category_slugs": [
          "marketing",
          "sales",
          "productivity"
      ],
      "description": "Create marketing content. Generate various formats and styles of text, such as slogans, blog posts, social media posts, etc., based on specific instructions and parameters to improve marketing efficiency and effectiveness.",
      "app": {
          "type_slug": "web"
      }
  },
];
