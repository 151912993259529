export const messages ={
  "MSG00001" : "认证失败：用户不存在。",
  "MSG00002" : "认证失败：密码不正确。",
  "MSG00003" : "认证失败：密码失效。",
  "MSG00004" : "请输入用户名和密码。",
  "MSG00005" : "用户一览情报取得失败。",
  "MSG00006" : "用户情报更新成功。",
  "MSG00007" : "用户情报更新失败。",
  "MSG00008" : "删除用户成功。",
  "MSG00009" : "删除用户失败。",
  "MSG00010" : "用户创建成功。",
  "MSG00011" : "用户创建失败。",
  "MSG00012" : "知识库删除成功！",
  "MSG00013" : "<span style='color:red'>LLM连接错误，不能回答您，请确认LLM是否连接正常。</span>",
  "MSG00014" : "未找到相关文档,该回答为大模型自身能力解答",
  "MSG00015" : "根据检索的已知信息以及AI模型的分析，没有发现风险，请调整参数后再次进行评估，比如，搜索引擎的检索结果件数等。",
  "MSG00016" : "",
  "MSG00017" : "",
  "MSG00018" : "",
  "MSG00019" : "",
  "MSG00020" : "",
  "MSG00021" : "",
  "MSG00022" : "",
  "MSG00023" : "",
  "MSG00024" : "",

  "CMSG00001" : "服务器错误。",
}