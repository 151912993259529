export const searchEngines = [
  { value: 'bing', label: 'bing' },
  { value: 'google', label: 'google' },
  // 添加更多搜索引擎...
];

export const models = [
  { value: 'zhipu-api', label: 'zhipu-api' },
  { value: 'qwen-api', label: 'qwen-api' },
  // 添加更多模型选项...
];

export const VC_DB = [
  { value: 'faiss', label: 'faiss' },
  { value: 'chromadb', label: 'chromadb' },
  { value: 'milvus', label: 'milvus' },
  { value: 'zilliz', label: 'zilliz' },
  { value: 'pg', label: 'pg' },
  { value: 'es', label: 'es' },
  // 添加更多模型选项...
];

export const embed_model = [
  { value: 'm3e-small', label: 'm3e-small' },
  { value: 'm3e-base', label: 'm3e-base' },
  { value: 'm3e-large', label: 'm3e-large' },
  { value: 'bge-small-zh', label: 'bge-small-zh' },
  { value: 'bge-base-zh', label: 'bge-base-zh' },
  { value: 'bge-large-zh', label: 'bge-large-zh' },
  { value: 'bge-base-zh-v1.5', label: 'bge-base-zh-v1.5' },
  { value: 'bge-large-zh-v1.5', label: 'bge-large-zh-v1.5' },
  // 添加更多向量库选项...
];

export const PROMPT_TEMPLATES_SEARCH_ENGINE = [
  { value: 'default', label: 'default' },
  { value: 'customPrompt', label: 'customPrompt' },
  { value: 'search', label: 'search' },
  // 添加更多提示词模板选项...
];

export const PROMPT_TEMPLATES_KNOWLEDGEBASE_ZH = [
  { value: 'default', label: 'default' },
  { value: 'customPrompt', label: 'customPrompt' },
  { value: 'text', label: 'Fine-Tuning-Prompt' },
  // 添加更多提示词模板选项...
];

export const PROMPT_TEMPLATES_KNOWLEDGEBASE_EN = [
  { value: 'ENG_default', label: 'default' },
  { value: 'customPrompt', label: 'customPrompt' },
  { value: 'ENG_text', label: 'Fine-Tuning-Prompt' },
  // 添加更多提示词模板选项...
];

export const PROMPT_TEMPLATES_SUPPLIER_RISK_ZH = [
  { value: 'default', label: 'default' },
  { value: 'customPrompt', label: 'customPrompt' },
  // { value: 'risk_assesment', label: 'risk_assesment' },
  { value: '风险Few-shot', label: '风险Few-shot' },
  { value: '风险分类Few-shot', label: '风险分类Few-shot' },
  { value: 'Co-star framework', label: 'Co-star framework' },
  { value: 'Co-star framework + Few shot', label: 'Co-star framework + Few shot' },
  // { value: 'Co-star framework + Few shot + resolution', label: 'Co-star framework + Few shot + resolution' },
  // 添加更多提示词模板选项...
];

export const PROMPT_TEMPLATES_SUPPLIER_RISK_EN = [
  { value: 'ENG_default', label: 'default' },
  { value: 'customPrompt', label: 'customPrompt' },
  // { value: 'risk_assesment', label: 'risk_assesment' },
  // { value: 'ENG_风险Few-shot', label: '风险Few-shot' },
  // { value: 'ENG_风险分类Few-shot', label: '风险分类Few-shot' },
  // { value: 'ENG_Co-star framework', label: 'Co-star framework' },
  { value: 'ENG_Co-star framework + Few shot', label: 'Co-star framework + Few shot' },
  // { value: 'Co-star framework + Few shot + resolution', label: 'Co-star framework + Few shot + resolution' },
  // 添加更多提示词模板选项...
];

export const SUPPLIER_LIST = [
  { value: '东实汽车科技集团股份有限公司', label: '东实汽车科技集团股份有限公司' },
  { value: '武汉东风工程建设监理有限公司', label: '武汉东风工程建设监理有限公司' },
  { value: '雪龙集团股份有限公司', label: '雪龙集团股份有限公司' },
  { value: 'TI', label: 'TI' },
  { value: '台积电', label: '台积电' },
  { value: 'ADI', label: 'ADI' },
  { value: 'NXP', label: 'NXP' },
  { value: '日月光', label: '日月光' },
  // 添加更多提示词模板选项...
];

export const language_setting = 'EN'
// export const language_setting = 'ZH'

