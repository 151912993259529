export const LabelConstants ={
  "label00001": "Login",
  "label00002": "UserName",
  "label00003": "Password",
  "label00004": "Please enter username and password.",
  "label00005": "KnowledgeBase Configuration",
  "label00006": "KnowledgeBase Query",
  "label00007": "Chat With Search Engine",
  "label00008": "Supplier Risk Assessment",
  "label00009": "Chat With Document",
  "label00010": "Generative BI",
  "label00011": "Chat With Search Engine and Internal Data",
  "label00012": "Data Extraction",
  "label00013": "Language Translation",
  "label00014": "Content Generation",
  "label00015": "Create User",
  "label00016": "ExpirationDate",
  "label00017": "DisplayName",
  "label00018": "Email",
  "label00019": "Role",
  "label00020": "Submit",
  "label00021": "Action",
  "label00022": "Yes",
  "label00023": "No",
  "label00024": "Settings",
  "label00025": "Permission Settings",
  "label00026": "Processing",
  "label00027": "User List",
  "label00028": "AI Application Platform", 
  "label00029": "Please select the application you need here. Feel free to consult us.",
  "label00030" : "Categories",
  "label00031" : "Application",
  "label00032" : "all",
  "label00033" : "agents",
  "label00034" : "customer-support",
  "label00035" : "entertainment",
  "label00036" : "finance",
  "label00037" : "hr",
  "label00038" : "marketing",
  "label00039" : "productivity",
  "label00040" : "programming",
  "label00041" : "sales",
  "label00042" : "Data",
  "label00043" : "Jobs",
  "label00044" : "History",
  "label00045" : "Settings",
  "label00046" : "Apps",
  "label00047" : "Logout",
  "label00048" : "AIGC Stack",
  "label00050" : "Create KnowledgeBase",
  "label00051" : "Delete KnowledgeBase",
  "label00052" : "KnowledgeBase Name",
  "label00053" : "Please enter the knowledgeBase name, do not enter Chinese",
  "label00054" : "KnowledgeBase Description",
  "label00055" : "Please enter the knowledgeBase description, such as knowledge scope, application scenarios, etc.",
  "label00056" : "Vector Library Type",
  "label00057" : "Vector Model",
  "label00058" : "Create",
  "label00059" : "Cancel",
  "label00060" : "KnowledgeBase Information:",
  "label00061" : "KnowledgeBase Description:",
  "label00062" : "Update",
  "label00063" : "Edit",
  "label00064" : "Creation Time",
  "label00065" : "Number of Files",
  "label00066" : "File Fragmentation Parameter Configuration:",
  "label00067" : "Text Fragment Length",
  "label00068" : "Adjacent Fragment Overlap Length",
  "label00069" : "Upload Knowledge File",
  "label00070" : "Upload Knowledge",
  "label00071" : "Drag and drop files here or ",
  "label00072" : "Click to upload",
  "label00073" : "Currently only supports uploading file types such as Md, Docx, Pdf, Xlsx, Csv, Html, Jsonl, Txt, Jpg, Jpeg, Png",
  "label00074" : "File Name:",
  "label00075" : "File Size:",
  "label00076" : "KnowledgeBase File Information:",
  "label00077" : "File Size: ",
  "label00078" : "Fragment Number: ",
  "label00079" : "Upload Time: ",
  "label00080" : "Download",
  "label00081" : "Fragment",
  "label00082" : "Save Changes",
  "label00083" : "Document Numbers:",
  "label00084" : "Current Page:",
  "label00085" : "Prev Page",
  "label00086" : "Next Page",
  "label00087" : "Delete",
  "label00088" : "Re-vectorize",
  "label00089" : "Select AI Model:",
  "label00090" : "Parameter Settings",
  "label00091" : "Select Prompt Template:",
  "label00092" : "View",
  "label00093" : "Copied",
  "label00094" : "Copy",
  "label00095" : "Custom System Prompt:",
  "label00096" : "Please enter custom system prompt",
  "label00097" : "History Length:",
  "label00098" : "KnowledgeBase Config",
  "label00099" : "Select knowledgebase:",
  "label00100" : "Num of Matching Knowledge Entries:",
  "label00101" : "Knowledge Matching Threshold:",
  "label00102" : "KnowledgeBase Matching Results",
  "label00103" : "Please enter query information...",
  "label00104" : "AI Processing",
  "label00105" : "Select information source time range：",
  "label00106" : "To",
  "label00107" : "Select supplier：",
  "label00108" : "Enter any supplier name：",
  "label00109" : "Start Evaluation",
  "label00110" : "AI Evaluating",
  "label00111" : "Network information source intelligence",
  "label00112" : "Search keywords:",
"label00113" : "Negative news and risk information War Disaster",
"label00114" : "Search engine configuration",
"label00115" : "Please select a search engine:",
"label00116" : "Number of matching search results:",
"label00117" : "Risk Plan knowledgeBase",
"label00118" : "ENG_text_RISK",



}